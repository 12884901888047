import React, {useState} from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

function SearchBox() {
    const [keyword, setKeyword] = useState('')
    
    let history = useHistory()

    const submitHandler = (e) => {
        e.preventDefault()
        if(keyword) {
            history.push(`/?keyword=${keyword}&page=1`)
        } else {
            history.push(history.location.pathname)
        }
    }

    return (
        <Form onSubmit={submitHandler}>
            <Row>
                <Col md={8}>
                    <Form.Control
                    type='text'
                    name='q'
                    className='mr-sm-2' 
                    placeholder='Search'
                    onChange={(e) => setKeyword(e.target.value)}
                    ></Form.Control>
                </Col>
                <Col md={4}>
                    <Button
                    type='submit'
                    variant='outline-secondary'
                    className='p-2'
                    >Search</Button>
                </Col>
            </Row>
            
        </Form>

    )
}

export default SearchBox
